<template>
  <div class="product-item">
    <h2 :id="name" class="name">{{ name }}</h2>
    <div class="card-container">
      <div class="card">
        <div class="tag"><span>WEB ショップ版</span></div>
        <img class="card-img" :src="mogumallImage" :alt="name" />
      </div>
      <div class="card">
        <div class="tag"><span>WEB ショップ版</span></div>
        <img class="card-img" :src="animateImage" :alt="name" />
      </div>
      <div class="card">
        <div class="tag"><span>TSUTAYA版</span></div>
        <img class="card-img" :src="tsutayaImage" :alt="name" />
      </div>
    </div>
    <div class="link-area">
      <link-button class="link" bgColor="#fac43e" :href="mogumallLink"
        >公式ショップ<br />商品詳細</link-button
      >
      <link-button class="link" bgColor="#85c8a9" :href="animateLink"
        >アニメイト<br />商品詳細</link-button
      >
      <link-button class="link" bgColor="#ec7993" :href="tsutayaLink"
        >TSUTAYA<br />商品詳細</link-button
      >
    </div>
    <small class="caution"
      >※お年玉キャンペーンの応募方法、抽選商品などの詳細は商品詳細ページからご確認お願いします。</small
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LinkButton from "./LinkButton.vue";

export default defineComponent({
  components: { LinkButton },
  props: {
    name: {
      type: String,
      required: true
    },
    mogumallLink: {
      type: String,
      required: true
    },
    animateLink: {
      type: String,
      required: true
    },
    tsutayaLink: {
      type: String,
      required: true
    },
    mogumallImage: {
      type: String
    },
    animateImage: {
      type: String
    },
    tsutayaImage: {
      type: String
    }
  },
  setup() {
    return {};
  }
});
</script>

<style lang="postcss" scoped>
.product-item {
  padding: 2% 4%;
}
.name {
  font-weight: 400;
}
.link-area {
  display: flex;
  justify-content: space-around;
  margin: 0.5rem 0;
  width: 100%;
}
.link {
  text-align: center;
  width: 33%;
}
.card-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin: 5% 0;
}
.card {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  width: 26%;
  align-items: start;
}
.card-img {
  width: 100%;
  margin: auto 0;
}
@media not screen and (min-width: 720px) {
  .tag {
    font-size: 10px;
    font-weight: bold;
    max-width: 72px;
    width: auto;
    border: solid 1px #000000;
    border-radius: 6px;
    margin: 0.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    & > span {
      white-space: nowrap;
      display: inline-block;
      transform: scale(0.8);
    }
  }
}
@media screen and (min-width: 720px) {
  .tag {
    padding: 2px 4px;
    font-size: 10px;
    font-weight: bold;
    max-width: 90px;
    width: auto;
    border: solid 1px #000000;
    border-radius: 6px;
    margin: 0.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    & > span {
      white-space: nowrap;
      display: inline-block;
    }
  }
}
</style>
