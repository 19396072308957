
import { defineComponent, ref } from "vue";
import TheHeader from "./components/TheHeader.vue";
import ShopLinks from "./components/ShopLinks.vue";
import TheFooter from "./components/TheFooter.vue";
import TheButton from "./components/TheButton.vue";
import Heading from "./components/Heading.vue";
import ProductItemList from "./components/ProductItemList.vue";
import About from "./components/About.vue";
import ActorListModal from "./components/ActorListModal.vue";

export default defineComponent({
  name: "App",
  components: {
    TheHeader,
    TheFooter,
    ShopLinks,
    TheButton,
    Heading,
    ProductItemList,
    About,
    ActorListModal
  },
  setup() {
    const onClickTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
    const isShowModal = ref(false);
    const openModal = () => {
      isShowModal.value = true;
    };
    const closeModal = () => {
      isShowModal.value = false;
    };

    return { onClickTop, openModal, isShowModal, closeModal };
  }
});
