<template>
  <footer class="footer">
    <img src="~@/assets/37card-logo.png" alt="37cardロゴ" />
    <small class="text-center copy">
      Copyright(C) MOGURA ENTERTAINMENT Co., Ltd. All Rights Reserved.
    </small>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="postcss" scoped>
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
}
.copy {
  font-size: 10px;
  margin-top: 2rem;
}
</style>
