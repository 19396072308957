
import { defineComponent } from "vue";
import LinkButton from "./LinkButton.vue";

export default defineComponent({
  components: { LinkButton },
  props: {
    name: {
      type: String,
      required: true
    },
    mogumallLink: {
      type: String,
      required: true
    },
    animateLink: {
      type: String,
      required: true
    },
    tsutayaLink: {
      type: String,
      required: true
    },
    mogumallImage: {
      type: String
    },
    animateImage: {
      type: String
    },
    tsutayaImage: {
      type: String
    }
  },
  setup() {
    return {};
  }
});
