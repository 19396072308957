<template>
  <a
    :style="`--bg-color: ${bgColor || ''}`"
    :class="`link${size ? '-' + size : ''}`"
    class="link"
    v-bind="$attrs"
    target="_blank"
    rel="noreferrer noopener"
  >
    <slot></slot>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

type Props = {
  size?: "lg";
  bgColor?: string;
};

export default defineComponent({
  props: {
    size: {
      type: String,
      default: null
    },
    bgColor: {
      type: String,
      default: null
    }
  },
  setup() {
    return {};
  }
});
</script>

<style lang="postcss" scoped>
.link {
  width: 80%;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 6px;
  border-radius: 15px;
  background-color: var(--bg-color);
  text-align: center;
  text-decoration: none;
  color: #221714;
  &-lg {
    font-size: 20px;
    font-weight: normal;
    padding: 0.75rem;
    border-radius: 25px;
  }
}
</style>
