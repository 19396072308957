<template>
  <button
    :style="`--bg-color: ${bgColor || ''}`"
    class="button"
    v-bind="$attrs"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

type Props = {
  bgColor?: string;
};

export default defineComponent({
  props: {
    bgColor: {
      type: String,
      default: null
    }
  }
});
</script>

<style lang="postcss" scoped>
.button {
  background-color: var(--bg-color);
  border-radius: 25px;
  color: #221714;
  font-size: 20px;
  padding: 0.75rem;
  text-align: center;
  text-decoration: none;
  width: 80%;
}
</style>
