<template>
  <div class="shop-links">
    <link-button
      class="link"
      size="lg"
      bgColor="#fac43e"
      href="http://www.mogumall.jp/categories/2991477"
      >公式 WEB ショップ</link-button
    >
    <link-button
      class="link"
      size="lg"
      bgColor="#85c8a9"
      href="https://www.animate-onlineshop.jp/products/list.php?sci=0&smt=%E5%B9%B4%E8%B3%80%E7%8A%B62021&ss=6&sl=80&nf=1&spc=1&scc=&ssy=&ssm=&sey=&sem=&nd[]=7"
      >アニメイト WEB ショップ</link-button
    >
    <link-button
      class="link"
      size="lg"
      bgColor="#ec7993"
      href="https://shop.tsutaya.co.jp/book/tokusyu/37card/"
      >TSUTAYA WEB ショップ</link-button
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LinkButton from "./LinkButton.vue";

export default defineComponent({
  components: { LinkButton }
});
</script>

<style lang="postcss" scoped>
.shop-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 360px;
  margin: 0 auto;
}
.link {
  margin: 1rem;
}
</style>
