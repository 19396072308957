<template>
  <div class="product-item-list">
    <product-item
      class="item"
      v-for="item in itemList"
      :key="item.name"
      :name="item.name"
      :mogumallLink="item.mogumallLink"
      :animateLink="item.animateLink"
      :tsutayaLink="item.tsutayaLink"
      :mogumallImage="item.mogumallImage"
      :animateImage="item.animateImage"
      :tsutayaImage="item.tsutayaImage"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProductItem from "./ProductItem.vue";

type Item = {
  name: string;
  mogumallLink: string;
  animateLink: string;
  tsutayaLink: string;
  mogumallImage: string;
  animateImage: string;
  tsutayaImage: string;
};

export default defineComponent({
  components: { ProductItem },
  setup() {
    const itemList: Array<Item> = [
      {
        name: "阿部敦",
        mogumallLink: "https://www.mogumall.jp/items/36681988",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E9%98%BF%E9%83%A8%E6%95%A6A/pd/1874918/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000178862/",
        mogumallImage: require("@/assets/mogumall/abe.jpg"),
        animateImage: require("@/assets/animate/abe.jpg"),
        tsutayaImage: require("@/assets/tsutaya/abe.jpg")
      },
      {
        name: "石谷春貴",
        mogumallLink: "https://www.mogumall.jp/items/36681961",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E7%9F%B3%E8%B0%B7%E6%98%A5%E8%B2%B4A/pd/1874898/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000178879/",
        mogumallImage: require("@/assets/mogumall/ishiya.jpg"),
        animateImage: require("@/assets/animate/ishiya.jpg"),
        tsutayaImage: require("@/assets/tsutaya/ishiya.jpg")
      },
      {
        name: "市川太一",
        mogumallLink: "https://www.mogumall.jp/items/36681893",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E5%B8%82%E5%B7%9D%E5%A4%AA%E4%B8%80A/pd/1874924/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000178909/",
        mogumallImage: require("@/assets/mogumall/ichikawa.jpg"),
        animateImage: require("@/assets/animate/ichikawa.jpg"),
        tsutayaImage: require("@/assets/tsutaya/ichikawa.jpg")
      },
      {
        name: "小笠原早紀",
        mogumallLink: "https://www.mogumall.jp/items/36680766",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E5%B0%8F%E7%AC%A0%E5%8E%9F%E6%97%A9%E7%B4%80A/pd/1874902/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000178886/",
        mogumallImage: require("@/assets/mogumall/ogasawara.jpg"),
        animateImage: require("@/assets/animate/ogasawara.jpg"),
        tsutayaImage: require("@/assets/tsutaya/ogasawara.jpg")
      },
      {
        name: "小野友樹",
        mogumallLink: "https://www.mogumall.jp/items/36680691",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E5%B0%8F%E9%87%8E%E5%8F%8B%E6%A8%B9A/pd/1874930/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000178893/",
        mogumallImage: require("@/assets/mogumall/ono.jpg"),
        animateImage: require("@/assets/animate/ono.jpg"),
        tsutayaImage: require("@/assets/tsutaya/ono.jpg")
      },
      {
        name: "梶裕貴",
        mogumallLink: "https://www.mogumall.jp/items/36680665",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E6%A2%B6%E8%A3%95%E8%B2%B4A/pd/1874932/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000178916//",
        mogumallImage: require("@/assets/mogumall/kaji.jpg"),
        animateImage: require("@/assets/animate/kaji.jpg"),
        tsutayaImage: require("@/assets/tsutaya/kaji.jpg")
      },
      {
        name: "小西克幸",
        mogumallLink: "https://www.mogumall.jp/items/36680616",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E5%B0%8F%E8%A5%BF%E5%85%8B%E5%B9%B8A/pd/1874944/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000178923/",
        mogumallImage: require("@/assets/mogumall/konishi.jpg"),
        animateImage: require("@/assets/animate/konishi.jpg"),
        tsutayaImage: require("@/assets/tsutaya/konishi.jpg")
      },
      {
        name: "小松昌平",
        mogumallLink: "https://www.mogumall.jp/items/36680583",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E5%B0%8F%E6%9D%BE%E6%98%8C%E5%B9%B3A/pd/1874916/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000178930/",
        mogumallImage: require("@/assets/mogumall/komatsu.jpg"),
        animateImage: require("@/assets/animate/komatsu.jpg"),
        tsutayaImage: require("@/assets/tsutaya/komatsu.jpg")
      },
      {
        name: "斎賀みつき",
        mogumallLink: "https://www.mogumall.jp/items/36680530",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E6%96%8E%E8%B3%80%E3%81%BF%E3%81%A4%E3%81%8DA/pd/1874906/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000178947//",
        mogumallImage: require("@/assets/mogumall/saiga.jpg"),
        animateImage: require("@/assets/animate/saiga.jpg"),
        tsutayaImage: require("@/assets/tsutaya/saiga.jpg")
      },
      {
        name: "佐藤拓也",
        mogumallLink: "https://www.mogumall.jp/items/36680489",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E4%BD%90%E8%97%A4%E6%8B%93%E4%B9%9FA/pd/1874934/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000178954/",
        mogumallImage: require("@/assets/mogumall/sato.jpg"),
        animateImage: require("@/assets/animate/sato.jpg"),
        tsutayaImage: require("@/assets/tsutaya/sato.jpg")
      },
      {
        name: "新祐樹",
        mogumallLink: "https://www.mogumall.jp/items/36680303",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E6%96%B0%E7%A5%90%E6%A8%B9A/pd/1874908/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000178961//",
        mogumallImage: require("@/assets/mogumall/shin.jpg"),
        animateImage: require("@/assets/animate/shin.jpg"),
        tsutayaImage: require("@/assets/tsutaya/shin.jpg")
      },
      {
        name: "鈴木崚汰",
        mogumallLink: "https://www.mogumall.jp/items/36680281",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E9%88%B4%E6%9C%A8%E5%B4%9A%E6%B1%B0A/pd/1874922/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000178978/",
        mogumallImage: require("@/assets/mogumall/suzuki.jpg"),
        animateImage: require("@/assets/animate/suzuki.jpg"),
        tsutayaImage: require("@/assets/tsutaya/suzuki.jpg")
      },
      {
        name: "立花慎之介",
        mogumallLink: "https://www.mogumall.jp/items/36682009",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E7%AB%8B%E8%8A%B1%E6%85%8E%E4%B9%8B%E4%BB%8BA/pd/1874946/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000178985/",
        mogumallImage: require("@/assets/mogumall/tachibana.jpg"),
        animateImage: require("@/assets/animate/tachibana.jpg"),
        tsutayaImage: require("@/assets/tsutaya/tachibana.jpg")
      },
      {
        name: "谷山紀章",
        mogumallLink: "https://www.mogumall.jp/items/36680250",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E8%B0%B7%E5%B1%B1%E7%B4%80%E7%AB%A0A/pd/1874938/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000178992/",
        mogumallImage: require("@/assets/mogumall/taniyama.jpg"),
        animateImage: require("@/assets/animate/taniyama.jpg"),
        tsutayaImage: require("@/assets/tsutaya/taniyama.jpg")
      },
      {
        name: "夏吉ゆうこ",
        mogumallLink: "https://www.mogumall.jp/items/36680218",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E5%A4%8F%E5%90%89%E3%82%86%E3%81%86%E3%81%93A/pd/1874940/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000179005/",
        mogumallImage: require("@/assets/mogumall/natsuyoshi.jpg"),
        animateImage: require("@/assets/animate/natsuyoshi.jpg"),
        tsutayaImage: require("@/assets/tsutaya/natsuyoshi.jpg")
      },
      {
        name: "野上翔",
        mogumallLink: "https://www.mogumall.jp/items/36680182",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E9%87%8E%E4%B8%8A%E7%BF%94A/pd/1874928/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000179012/",
        mogumallImage: require("@/assets/mogumall/nogami.jpg"),
        animateImage: require("@/assets/animate/nogami.jpg"),
        tsutayaImage: require("@/assets/tsutaya/nogami.jpg")
      },
      {
        name: "畠中祐",
        mogumallLink: "https://www.mogumall.jp/items/36680151",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E7%95%A0%E4%B8%AD%E7%A5%90A/pd/1874942/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000179036/",
        mogumallImage: require("@/assets/mogumall/hatanaka.jpg"),
        animateImage: require("@/assets/animate/hatanaka.jpg"),
        tsutayaImage: require("@/assets/tsutaya/hatanaka.jpg")
      },
      {
        name: "濱健人",
        mogumallLink: "https://www.mogumall.jp/items/36680116",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E6%BF%B1%E5%81%A5%E4%BA%BAA/pd/1874910/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000179029/",
        mogumallImage: require("@/assets/mogumall/hama.jpg"),
        animateImage: require("@/assets/animate/hama.jpg"),
        tsutayaImage: require("@/assets/tsutaya/hama.jpg")
      },
      {
        name: "林勇",
        mogumallLink: "https://www.mogumall.jp/items/36680073",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E6%9E%97%E5%8B%87A/pd/1874936/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000179043/",
        mogumallImage: require("@/assets/mogumall/hayashi.jpg"),
        animateImage: require("@/assets/animate/hayashi.jpg"),
        tsutayaImage: require("@/assets/tsutaya/hayashi.jpg")
      },
      {
        name: "ファイルーズあい",
        mogumallLink: "https://www.mogumall.jp/items/36680037",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%83%BC%E3%82%BA%E3%81%82%E3%81%84A/pd/1874900/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000179050/",
        mogumallImage: require("@/assets/mogumall/ai.jpg"),
        animateImage: require("@/assets/animate/ai.jpg"),
        tsutayaImage: require("@/assets/tsutaya/ai.jpg")
      },
      {
        name: "福山潤",
        mogumallLink: "https://www.mogumall.jp/items/36680013",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E7%A6%8F%E5%B1%B1%E6%BD%A4A/pd/1874948/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000179067/",
        mogumallImage: require("@/assets/mogumall/fukuyama.jpg"),
        animateImage: require("@/assets/animate/fukuyama.jpg"),
        tsutayaImage: require("@/assets/tsutaya/fukuyama.jpg")
      },
      {
        name: "保住有哉",
        mogumallLink: "https://www.mogumall.jp/items/36679859",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E4%BF%9D%E4%BD%8F%E6%9C%89%E5%93%89A/pd/1874904/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000179074/",
        mogumallImage: require("@/assets/mogumall/hozumi.jpg"),
        animateImage: require("@/assets/animate/hozumi.jpg"),
        tsutayaImage: require("@/assets/tsutaya/hozumi.jpg")
      },
      {
        name: "増元拓也",
        mogumallLink: "https://www.mogumall.jp/items/36679799",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E5%A2%97%E5%85%83%E6%8B%93%E4%B9%9FA/pd/1874912/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000179081/",
        mogumallImage: require("@/assets/mogumall/masumoto.jpg"),
        animateImage: require("@/assets/animate/masumoto.jpg"),
        tsutayaImage: require("@/assets/tsutaya/masumoto.jpg")
      },
      {
        name: "益山武明",
        mogumallLink: "https://www.mogumall.jp/items/36679659",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E7%9B%8A%E5%B1%B1%E6%AD%A6%E6%98%8EA/pd/1874914/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000179098/",
        mogumallImage: require("@/assets/mogumall/masuyama.jpg"),
        animateImage: require("@/assets/animate/masuyama.jpg"),
        tsutayaImage: require("@/assets/tsutaya/masuyama.jpg")
      },
      {
        name: "村瀬歩",
        mogumallLink: "https://www.mogumall.jp/items/36679590",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E6%9D%91%E7%80%AC%E6%AD%A9A/pd/1874926/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000179104/",
        mogumallImage: require("@/assets/mogumall/murase.jpg"),
        animateImage: require("@/assets/animate/murase.jpg"),
        tsutayaImage: require("@/assets/tsutaya/murase.jpg")
      },
      {
        name: "八代拓",
        mogumallLink: "https://www.mogumall.jp/items/36679550",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E5%85%AB%E4%BB%A3%E6%8B%93A/pd/1874894/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000179111/",
        mogumallImage: require("@/assets/mogumall/yashiro.jpg"),
        animateImage: require("@/assets/animate/yashiro.jpg"),
        tsutayaImage: require("@/assets/tsutaya/yashiro.jpg")
      },
      {
        name: "山本希望",
        mogumallLink: "https://www.mogumall.jp/items/36679518",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E5%B1%B1%E6%9C%AC%E5%B8%8C%E6%9C%9BA/pd/1874896/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000179128/",
        mogumallImage: require("@/assets/mogumall/yamamoto.jpg"),
        animateImage: require("@/assets/animate/yamamoto.jpg"),
        tsutayaImage: require("@/assets/tsutaya/yamamoto.jpg")
      },
      {
        name: "代永翼",
        mogumallLink: "https://www.mogumall.jp/items/36678045",
        animateLink:
          "https://www.animate-onlineshop.jp/pn/%E3%80%90%E3%82%B0%E3%83%83%E3%82%BA-%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%80%91%E5%B9%B4%E8%B3%80%E7%8A%B62021+%E4%BB%A3%E6%B0%B8%E7%BF%BCA/pd/1874920/",
        tsutayaLink: "https://shop.tsutaya.co.jp/book/product/2510000179135/",
        mogumallImage: require("@/assets/mogumall/yonaga.jpg"),
        animateImage: require("@/assets/animate/yonaga.jpg"),
        tsutayaImage: require("@/assets/tsutaya/yonaga.jpg")
      }
    ];
    return { itemList };
  }
});
</script>

<style lang="postcss" scoped>
.product-item-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

@media not screen and (min-width: 720px) {
  .item {
    width: 100%;
  }
  .item:nth-child(odd) {
    background-color: #faf8f8;
  }
}
@media screen and (min-width: 720px) {
  .item {
    width: 50%;
    &:nth-child(4n + 1) {
      background-color: #faf8f8;
    }
    &:nth-child(4n) {
      background-color: #faf8f8;
    }
  }
}
</style>
