<template>
  <header class="header">
    <div class="loop">
      <img class="top-img" src="@/assets/loop-image.jpg" />
      <img class="top-img" src="@/assets/loop-image.jpg" />
    </div>
    <img class="hero-img" src="~@/assets/hero.png" alt="37card年賀状" />
    <div class="loop">
      <img class="bottom-img" src="@/assets/loop-image2.jpg" />
      <img class="bottom-img" src="@/assets/loop-image2.jpg" />
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="postcss" scoped>
.header {
  line-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fdf8e5;
}
.hero-img {
  width: 100%;
  max-width: 720px;
  margin: auto 0;
}
.loop {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}
@media screen and (max-width: 719px) {
  .loop {
    height: 110px;
  }
}
@media screen and (min-width: 720px) {
  .loop {
    height: 200px;
  }
}
.top-img {
  width: auto;
  height: 100%;
  &:first-child {
    animation: loop-top 200s -100s linear infinite;
  }
  &:last-child {
    animation: loop-top2 200s linear infinite;
  }
}

.bottom-img {
  width: auto;
  height: 100%;
  &:first-child {
    animation: loop-bottom 200s -100s linear infinite;
  }
  &:last-child {
    animation: loop-bottom2 200s linear infinite;
  }
}

@keyframes loop-top {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes loop-top2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}
@keyframes loop-bottom {
  0% {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes loop-bottom2 {
  0% {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(0);
  }
}
</style>
