<template>
  <div class="actor-list-modal">
    <img class="close" src="@/assets/close.svg" @click="onClickClose" />
    <h2 class="text-center">参加声優一覧</h2>
    <p class="text-center spacing">※五十音順</p>
    <p class="text-center spacing weight-thin">
      お名前を押すと商品一覧に飛びます。
    </p>
    <div class="link-area">
      <a
        class="link"
        v-for="actor in actorList"
        :key="actor"
        :href="`#${actor}`"
        @click="onClickActor"
      >
        {{ actor }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onClickClose: {
      type: Function,
      required: true
    },
    onClickActor: {
      type: Function,
      required: true
    }
  },
  setup() {
    const actorList = [
      "阿部敦",
      "石谷春貴",
      "市川太一",
      "小笠原早紀",
      "小野友樹",
      "梶裕貴",
      "小西克幸",
      "小松昌平",
      "斎賀みつき",
      "佐藤拓也",
      "新祐樹",
      "鈴木崚汰",
      "立花慎之介",
      "谷山紀章",
      "夏吉ゆうこ",
      "野上翔",
      "畠中祐",
      "濱健人",
      "林勇",
      "ファイルーズあい",
      "福山潤",
      "保住有哉",
      "増元拓也",
      "益山武明",
      "村瀬歩",
      "八代拓",
      "山本希望",
      "代永翼"
    ];

    return { actorList };
  }
};
</script>

<style lang="postcss" scoped>
.actor-list-modal {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: 100;
  padding: 40px 10px;
  overflow: scroll;
}
.close {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.link-area {
  margin: 0 auto;
  width: 320px;
  padding: 2rem;
}
.link {
  font-size: 20px;
  display: block;
  margin-top: 1rem;
}
.spacing {
  margin-top: 1.5rem;
}
.weight-thin {
  font-weight: 300;
}
</style>
