<template>
  <div class="about">
    <img class="sell-info" src="@/assets/sell-info.png" alt="予約・販売日時" />
    <img class="scroll-img" src="@/assets/scroll.png" alt="スクロール" />
    <div class="new-year-card">
      <img class="frame-sp" src="@/assets/yellow-frame.png" alt="" />
      <img class="frame frame-y" src="@/assets/ushi_y_frame.png" alt="" />
      <div class="content">
        <heading color="#ffd440">年賀状37cardについて</heading>
        <p>
          「年賀状37card（サンナナカード）」は人気声優からファンの皆様へ新年のご挨拶を電話でお伝えするボイスメッセージ付き年賀状風のポストカードです。
        </p>
        <p>
          年賀状には抽選コードが記載されており、年賀状を購入し、2021年1月20日（水）までにボイスメッセージを電話で受け取って頂いた方を対象に抽選でサイン入り色紙やアザーカット年賀状などが当たるお年玉キャンペーンを実施します！
        </p>
        <small class="caution-left"
          >※抽選特典内容は商品によって異なります<br />
          ※抽選締切日変更のお知らせ<br />
          <span class="bold">2021年1月13日（水）→2021年1月20日（水）</span>
        </small>
      </div>
    </div>
    <div class="product">
      <img class="frame-sp" src="@/assets/blue-frame.png" alt="" />
      <img class="frame frame-b" src="@/assets/ushi_b_frame.png" alt="" />
      <div class="content">
        <heading color="#79a4d6">商品について</heading>
        <div class="card-container">
          <figure class="card">
            <img
              class="card-img"
              src="@/assets/mogumall/kaji.jpg"
              alt="商品サンプル表"
            />
            <figcaption>表面</figcaption>
          </figure>
          <figure class="card">
            <img
              class="card-img"
              src="@/assets/nengajo-ura.jpg"
              alt="商品サンプル裏"
            />
            <figcaption>裏面</figcaption>
          </figure>
          <figure class="card">
            <img
              class="card-img"
              src="@/assets/nengajo-envelope.jpg"
              alt="封筒サンプル"
            />
            <figcaption>封筒</figcaption>
          </figure>
        </div>
        <p class="">
          「年賀状37card」は年賀状風のポストカードです。表面に撮り下ろし写真、裏面に電話番号とシークレットコード、抽選コード（シークレットコードと同じ番号です）、ご利用方法などの情報が記載されています。商品はオリジナル封筒に入れてお届けします。
        </p>
        <p class="spacing">
          商品は各声優お一人につき3種類ございます。3種類のうち2種類は公式WEBショップ、またはアニメイトWEBショップ・各店舗で、1種類はTSUTAYA
          WEBショップ、各店舗でご購入頂けます。
        </p>
        <small class="caution"
          >※予約状況によっては、アニメイト各店舗での販売が実施されない可能性がございます。予めご了承ください。</small
        >
        <p class="detail">種類: 全28名、1名につき各3種</p>
        <p class="detail">
          販売価格 (税別): 1種1,000円
        </p>
        <p class="detail">仕様: 100mm×148mm</p>
        <p class="detail">発売元: MOGURA ENTERTAINMENT</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Heading from "./Heading.vue";

export default defineComponent({
  components: { Heading },
  setup() {
    return {};
  }
});
</script>

<style lang="postcss" scoped>
.about {
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 420px) {
  .frame-sp {
    width: 100%;
    max-width: 420px;
    min-width: 320px;
    overflow: hidden;
  }
  .frame {
    display: none;
  }
}

@media not screen and (max-width: 420px) {
  .new-year-card,
  .product {
    overflow: hidden;
    max-width: 100% !important;
  }
  .new-year-card {
    & > .content {
      top: 39% !important;
    }
  }
  .product {
    & > .content {
      top: 22% !important;
    }
  }
  .frame-sp {
    display: none;
  }
  .frame {
    &-y {
      height: 645px;
    }
    &-b {
      height: 780px;
    }
  }
}

.new-year-card {
  width: 100%;
  max-width: 420px;
  min-width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &::before {
    padding-top: 100px;
  }
  .caution {
    &-left {
      text-align: left;
      margin-right: 12px;
      line-height: 12px;
      display: block;
    }
    text-align: right;
    margin-right: 12px;
  }
  .content {
    top: 38%;
  }
}
.sell-info {
  width: 100%;
  max-width: 420px;
  margin-bottom: -100px;
  z-index: 10;
}
.scroll-img {
  margin-top: 100px;
  margin-bottom: -160px;
  z-index: 10;
}
.product {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -3%;
  .content {
    top: 12%;
  }
  .caution {
    margin-bottom: 5px;
  }
}

.content {
  position: absolute;
  max-width: 420px;
  width: 80%;
  font-size: 14px;
}

.spacing {
  margin: 0.5rem 0;
}
.detail {
  font-size: 10px;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 360px;
}
.card {
  margin: 0.5rem 0;
  & > figcaption {
    color: #79a4d6;
    font-size: 10px;
    text-align: center;
  }
}
.card-img {
  max-height: 82px;
  max-width: 82px;
  height: auto;
}
.text-red {
  color: red;
}
.bold {
  font-weight: 700;
}
</style>
