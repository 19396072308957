
import { defineComponent } from "vue";
import Heading from "./Heading.vue";

export default defineComponent({
  components: { Heading },
  setup() {
    return {};
  }
});
