
import { defineComponent } from "vue";

type Props = {
  size?: "lg";
  bgColor?: string;
};

export default defineComponent({
  props: {
    size: {
      type: String,
      default: null
    },
    bgColor: {
      type: String,
      default: null
    }
  },
  setup() {
    return {};
  }
});
