<template>
  <h2 :style="`--color: ${color || '#000'}`" class="heading">
    <span><slot /></span>
  </h2>
</template>

<script lang="ts">
import { defineComponent } from "vue";

type Props = {
  color?: string;
};

export default defineComponent({
  props: {
    color: {
      type: String,
      default: null
    }
  },
  setup() {
    return {};
  }
});
</script>

<style scoped>
.heading {
  text-align: center;
  color: var(--color);
  font-weight: 400;
  font-size: 22px;
  & > span {
    display: inline-block;
    &::after {
      background-image: radial-gradient(var(--color) 30%, transparent 30%);
      background-repeat: space;
      background-size: 8px 8px;
      content: "";
      display: block;
      height: 8px;
      width: 100%;
    }
  }
}
</style>
