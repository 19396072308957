
import { defineComponent } from "vue";

type Props = {
  color?: string;
};

export default defineComponent({
  props: {
    color: {
      type: String,
      default: null
    }
  },
  setup() {
    return {};
  }
});
