
import { defineComponent } from "vue";

type Props = {
  bgColor?: string;
};

export default defineComponent({
  props: {
    bgColor: {
      type: String,
      default: null
    }
  }
});
