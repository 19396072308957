<template>
  <the-header />
  <about :onClickTop="onClickTop" />
  <shop-links class="shop-links" />
  <div class="campaign-wrap">
    <div class="campaign">
      <img class="frame" src="@/assets/ushi_r_frame.png" alt="" />
      <div class="campaign-content">
        <heading color="#ff1714">お年玉抽選キャンペーン</heading>
        <p>
          カード裏面に記載された、12桁の番号が抽選番号です。抽選結果は、1月中に37card公式Twitter（@37card）にて発表いたします。
        </p>
        <small class="caution"
          >※お年玉キャンペーンの応募方法、抽選商品などの詳細は商品詳細ページからご確認お願いします。</small
        >
        <img
          class="code-sample"
          src="@/assets/nengajo-code.jpg"
          alt="抽選番号のサンプル"
        />
      </div>
    </div>
  </div>
  <div class="button-area">
    <the-button bgColor="#7bc178" @click="openModal">参加声優一覧</the-button>
  </div>
  <heading class="product-lineup" color="#bed503">商品ラインナップ</heading>
  <product-item-list />
  <the-footer />
  <img class="top" src="@/assets/top.png" @click="onClickTop" />
  <actor-list-modal
    v-if="isShowModal"
    :onClickActor="closeModal"
    :onClickClose="closeModal"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import TheHeader from "./components/TheHeader.vue";
import ShopLinks from "./components/ShopLinks.vue";
import TheFooter from "./components/TheFooter.vue";
import TheButton from "./components/TheButton.vue";
import Heading from "./components/Heading.vue";
import ProductItemList from "./components/ProductItemList.vue";
import About from "./components/About.vue";
import ActorListModal from "./components/ActorListModal.vue";

export default defineComponent({
  name: "App",
  components: {
    TheHeader,
    TheFooter,
    ShopLinks,
    TheButton,
    Heading,
    ProductItemList,
    About,
    ActorListModal
  },
  setup() {
    const onClickTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
    const isShowModal = ref(false);
    const openModal = () => {
      isShowModal.value = true;
    };
    const closeModal = () => {
      isShowModal.value = false;
    };

    return { onClickTop, openModal, isShowModal, closeModal };
  }
});
</script>

<style lang="postcss">
@import url("style/index.pcss");
</style>

<style lang="postcss" scoped>
.shop-links {
  margin-top: 2rem;
}
.button-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 360px;
  margin: 0 auto;
}
.product-lineup {
  margin: 2.5rem 0;
}
.campaign {
  font-weight: 300;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4em 0;
  &-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &-content {
    position: absolute;
    max-width: 300px;
    width: 76%;
    & > p {
      margin: 8px 0;
      font-size: 14px;
    }
    & > img {
      width: 100%;
    }
  }
}
.frame {
  height: 540px;
}
.top {
  max-height: 80px;
  max-width: 80px;
  width: auto;
  position: sticky;
  bottom: 5%;
  left: 90%;
  margin: 20px;
  cursor: pointer;
}
@media screen and (min-width: 720px) {
  .button-area {
    display: none;
  }
  .product-lineup {
    margin-top: 0;
  }
}
</style>
